@font-face {
    font-family: 'Dubai';
    src: url('./fonts/Dubai-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dubai';
    src: url('./fonts/Dubai-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dubai';
    src: url('./fonts/Dubai-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dubai';
    src: url('./fonts/Dubai-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

