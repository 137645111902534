.container {
  /* background-color: #EE000030; */
  display: flex;
  flex-direction: column;
  padding-inline: 8px;
  overflow-x: auto;
}
.sum {
  background-color: #FFF;
  border-radius: 10px;
  width: auto;
  padding: 10px;
  margin-inline-start: 10px;
  margin-top: 20px;
  border: 1px solid rgb(221, 221, 221);
  tr, td {
    border: 0
  }
}

.productTable {
  margin-bottom: 40px;
}

.section {
  background-color: #b9d8ff;
  padding: 6px;
  border-radius: 8px;
  margin: 10px 0 20px 0;
  h3 {
    margin: 5px 10px 20px 0;
  }
}

.productTableName {
  td {
    border: 0;
    &:first-child {
      background-color: #a5a5a5;
      padding-inline-start: 16px;
      border-radius: 10px;
    }
    &:last-child {
      padding-inline-start: 40px;
      font-size: 0.9rem;
    }
  }
}
