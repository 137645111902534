@import './normalize.css';
@import './fonts.css';

html,
body {
  direction: rtl;
  font-family: Dubai;
  margin: 0;
  padding: 0;
}

span.small {
  font-size: 0.9rem;
}

h3.small {
  margin: 0.3rem;
}


.table-container {
  background-color: #FFF;
  border-color: pink;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  margin: 0 1rem;
  overflow: auto;
}

table {
  width: 100%;
  padding: .1rem;
  color: #212529;
}

td {
  padding: 0.5rem 0.5rem;
  border: 0;
  border-bottom: 1px solid rgba(0,0,0,.125);
}

td {
  white-space: nowrap;
}

td.text-break-spaces {
  white-space: unset;
}

td:first-child {
  width: 37px;
  border-left: 1px solid rgba(0,0,0,.125);
  /* border-bottom:  */
}

tr:first-child {
  border: 11px solid rgba(0,0,0,.125);
}

a {
  text-decoration: none;
  color: #191919;
}

.review-container {
  padding-inline: 8px;
  margin-inline: 8px;
  margin-block: 20px;
  background-color: #b9d8ff;
  border-radius: 8px;
  padding-block-end: 30px;
}


.review-container h4.section {
  padding: 15px 10px 20px 0;
}

/* td.value {
  white-space: normal;
} */

td.product-name {
  white-space: normal;
}

td.balance-note {
  white-space: normal;
}
/*
.container .table-responsive {
  width: 100%;
  overflow-y: auto;
}


table {
  width: 100%;
}

.table-responsive table tbody tr:first-child td {
  padding-top: 10px;
}

td {
  padding: 10px;
}
.table-responsive table tbody tr:last-child td {
  padding-bottom: 10px;
}

td {
  white-space: nowrap;
}
td.label {
  min-width: 130px;
}

h4.section {
  text-decoration: underline;
  margin-top: 50px;
}

.chipset {
  background-color: #d5d5d5;
  padding: 6px 2px;
  border-radius: 5px;
}
 */

 .value.negative {
  color: red;
}

.value.positive {
  color: green;
}
