.wrapper {
  background-color:#384855;
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.container {
  margin: 30px auto;
  /* padding: 40px; */
  max-width: 720px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 8px 8px 2px 2px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 720px) {
  .container {
    margin: 0 auto;
    padding: 0;
    border-radius: 0;
  }
}

.header {
  height: 140px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dde5f1;
}

.logo {
  padding: 20px 30px 10px;
  width: 70px;
}

.logo img {
  height: 100%;
}

.content {
  background-color: #edf5ff;
  flex-grow: 1;
}

.description {
  width: 210px;
  padding: 20px 10px 20px 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.right {
  display: flex;
}

.left {
  display: flex;
}

.footer {
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.block {
 padding: 10px 30px;
}
