.main {
    /* max-width: 900px; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: 1rem;  
}
.header {
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
/* 

.header .details {
    font-size: 1.1rem;
}

.content {
    flex: 1;
} */

/* .content table {
    width: 100%;
} */
/* 
.content{
    padding: 15px;
}

.content table tr {
    background-color: white;
}

.content table tr {
    background-color: white;
}

.content table td {
    padding: 2px 5px;
}


.footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 50px;
}

.footerBox {
    flex: 1;
    padding: 5px 10px;
} */
